<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 d-flex align-items-stretch mb-3">
                <div class="row flex-grow">
                    <div class="col-12">
                        <div class="text-left">
                            <b-btn v-on:click="resetForm" variant="primary">
                                {{ $t('add-fix-location') }}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="fixmodalmd" ref="vuemodal2" hide-footer :title="title">
                <div>
                    <form @submit.stop.prevent="onSubmitFix">
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <!-- <b-form-group>
                                    <b-form-select v-model="subForm.selectedFixLocation" :options="subForm.fixLocations" value-field="id" text-field="name" @change="getFixLocation($event)">
                                        <b-form-select-option v-if="subForm.fixLocations.length === 0" :disabled=t rue>{{ $t('No Fix Location Found') }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group> -->
                                <b-form-input type="text" id="location_id" name="location_id" v-model="subForm.location_id" hidden=true></b-form-input>
                                <b-form-group :label="$t('name*')" label-for="name">
                                    <b-form-input type="text" id="name" name="name" v-model="$v.subForm.name.$model" :placeholder="$t('name-placeholder')" :state="$v.subForm.name.$dirty ? !$v.subForm.name.$error : null" aria-describedby="input-1-live-feedback">
                                    </b-form-input>
                                    <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group :label="$t('latitude*')" label-for="latitude">
                                    <b-form-input type="text" id="latitude" autocomplete="new-password" :placeholder="$t('latitude')" v-model="$v.subForm.latitude.$model" :state="$v.subForm.latitude.$dirty ? !$v.subForm.latitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.subForm.latitude.between" id="input-2-live-feedback">
                                        {{ $t("Value must be between -90 and 90") }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.subForm.latitude.required"
                                        id="input-1-live-feedback">{{ $t("field-required") }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group :label="$t('longitude*')" label-for="longitude">
                                    <b-form-input type="text" id="longitude" autocomplete="new-password" :placeholder="$t('longitude')" v-model="$v.subForm.longitude.$model" :state="$v.subForm.longitude.$dirty ? !$v.subForm.longitude.$error : null" aria-describedby="input-1-live-feedback"></b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.subForm.longitude.between" id="input-2-live-feedback">
                                        {{ $t("Value must be between -180 and 180") }}
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.subForm.longitude.required"
                                        id="input-1-live-feedback">{{ $t("field-required") }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <b-button v-if="subForm.id == ''" type="submit" variant="primary" class="mr-2">
                                    <span>{{ $t('submit-btn') }}</span>
                                </b-button>
                                <b-button v-else type="submit" variant="primary" class="mr-2">
                                    <span>{{ $t('update-btn') }}</span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('fix-locations') }}: {{ locationName }}</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="columns">
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field === 'action'"
                                    class="text-nowrap"
                                >
                                <toggle-button
                                    v-model="locations.find(p => p.id === props.row.id).show"
                                    @change="changeStatus(props.row.id, $event)"
                                    :sync="true"
                                    class="mr-1 switchColor"/>
                                <b-button size="sm"
                                    @click="editFixLocation(props.row.id)"
                                    class="mr-1 btn-info text-white">
                                    {{ $t('edit-btn') }}
                                </b-button>
                                <!-- <b-button size="sm"
                                    @click="goToFixLocations(props.row.id)"
                                    class="mr-1 btn-info text-white">
                                    {{ $t('fix-locations') }}
                                </b-button> -->
                                <b-button size="sm" @click="deleteFixLocation(props.row.id)"
                                    class="mr-1 btn-danger text-white">
                                    {{ $t('delete-btn') }}
                                </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs,
        between
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'FixLocations',
        mixins: [validationMixin],
        data() {
            return {
                // form: {
                //     name: "",
                //     id: "",
                //     client_id: JSON.parse(localStorage.getItem('client_id')),
                //     show: true,
                // },
                subForm: {
                    id: "",
                    name: "",
                    longitude: "",
                    latitude: "",
                    location_id: "",
                    fixedLocations: [],
                    fixLocations: [],
                    selectedFixLocation: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                },
                subLocations: [{}],
                isDisabled: false,
                title: i18n.t('add-fix-location'),
                serverParams: {
                    location_id: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [{
                        field: 'id', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }],
    
                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Latitude',
                        field: 'latitude',
                        filterable: true
                    },
                    {
                        label: 'Longitude',
                        field: 'longitude',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                locations: [],
                FixedLocation: '',
                locationName: ''
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                },
            },
            subForm: {
                name: {
                    required
                },
                latitude: {
                    required,
                    between(value) {
                        return between(-90, 90)(value)
                    }
                },
                longitude: {
                    required,
                    between(value) {
                        return between(-180, 180)(value)
                    }
                },
            }
        },
        methods: {
            getFixLocation(e) {
                this.subForm.selectedFixLocation = e;
                const data = {
                    id: e,
                };
                API.getFixLocation(
                    data,
                    data => {
                        this.subForm.name = data.name;
                        this.subForm.longitude = data.longitude;
                        this.subForm.latitude = data.latitude;
                    },
                    err => {}
                );
            },
            getLocation(e) {
                const data = {
                    id: e,
                    client_id: JSON.parse(localStorage.getItem('client_id'))
                }
                API.getEditLocation(
                    data, data => {
                        if(data.status == 200) {
                            this.locationName = data.data.location.name;
                            // this.form.id =  data.data.location.id;
                            // this.subForm.location_id = data.data.location.id;
                            // this.subForm.fixLocations = data.data.fixLocations;
                            // this.form.show = (data.data.location.show == 1) ? true : false;
                        }
                    },
                    err => {}
                )
            },
            changeStatus(id, event) {
                console.log(id, event.value);
                const data = {
                    id: id,
                    show: event.value === true ? 1 : 0
                };
                API.updateFixLocationStatus(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            deleteFixLocation(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        API.deleteFixLocation(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record has been deleted.');
                            },
                            err => {})
                    }
                })
            },
            editFixLocation(e) {
                this.subForm.id = e;
                this.$v.subForm.$reset();
                this.subForm.name = '';
                this.subForm.longitude = '';
                this.subForm.latitude = '';
                this.$root.$emit("bv::show::modal", "fixmodalmd");
                // this.resetForm();
                this.title = i18n.t('edit-fix-location');
                const data = {
                    id: e,
                    client_id: JSON.parse(localStorage.getItem('client_id'))
                }
                API.getFixLocation(
                    data, data => {
                        this.subForm.name = data.name;
                        this.subForm.longitude = data.longitude;
                        this.subForm.latitude = data.latitude;
                    },
                    err => {}
                )
            },
            getFixLocations() {
                const data = {
                    // client_id: JSON.parse(localStorage.getItem('client_id')),
                    client_id: this.client_id,
                    location_id: this.location_id,
                }
                API.getFixLocations(
                    data,
                    data => {
                        this.fixedLocations = data.data;
                    },
                    err => {}
                )
    
            },
            onSubmitFix() {
                this.$v.subForm.$touch();
                if (this.$v.subForm.$anyError) {
                    return;
                }
                if (this.subForm.name != '') {
                    let data = {
                        id: this.subForm.id,
                        name: this.subForm.name,
                        client_id: this.subForm.client_id,
                        latitude: this.subForm.latitude,
                        longitude: this.subForm.longitude,
                        location_id: this.subForm.location_id
                    }
                    API.addEditFixLocations(
                        data,
                        data => {
                            this.fixedLocations = data.data;
                            if (data.status === 200) {
                                this.$refs["vuemodal2"].$refs['close-button'].click();
                                this.$snotify.success(data.message);
                                this.$v.subForm.$reset();
                                this.subForm.name = '';
                                this.subForm.longitude = '';
                                this.subForm.latitude = '';
                                this.loadItems();
                                // this.getFixLocation(this.subForm.selectedFixLocation);
                                this.subForm.selectedFixLocation = '';
    
                            } else {
                                this.$snotify.error(data.message);
                            }
                        },
    
                        err => {}
                    );
                } else {
                    this.$snotify.warning('Please fill required fields');
                    // this.isDisabled = false;
                }
    
            },
            loadItems() {
                this.isLoading = true;
                API.getAllFixLocations(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
                                show: q.show === 1 ? true : false
    
                            };
                            this.locations.push(data);
                        });
                    },
                    err => {}
                )
            },
            resetForm() {
                this.title = i18n.t('add-fix-location');
                this.subForm.id = '';
                this.subForm.name = '';
                this.subForm.longitude = '';
                this.subForm.latitude = '';
                this.$v.subForm.$reset();
                this.$root.$emit("bv::show::modal", "fixmodalmd");
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
    
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
    
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
        },
        computed: {},
        created() {
            // this.getFixLocations();
        },
        mounted() {
            this.serverParams.location_id = this.$route.params.location_id;
            this.subForm.location_id = this.$route.params.location_id;
            this.isLoading = true;
            this.loadItems();
            this.getLocation(this.serverParams.location_id);
        }
    }
</script>


<style>
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    
    .submit-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        margin: 0 auto;
    }
    
    .new-location {
        float: right;
    }
    
    .save-btn {
        float: left;
    }
</style>
